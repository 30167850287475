import HeaderLogo from '@/assets/img/logo_active@2x.png';
import ImgError from '@/assets/img/sys_error@2x.png';
import Footer from '@/components/Footer';
import Image from '@/components/Image';
import { Tit } from '@/components/Titles';
import GlobalStyle from '@/GlobalStyle';
import { breakpoint } from '@/helpers/BreakpointHelper';
import { Link, PageProps } from 'gatsby';
import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  height: 100vh;
  min-height: 1000px;
  position: relative;

  footer {
    position: absolute;
    left: 0;
    width: 100%;
    bottom: 0;
  }

  ${breakpoint(`tablet`)} {
    height: auto;
    min-height: 0;
    footer {
      display: none;
    }
  }
`;
const Header = styled.header`
  padding: 50px 0;

  ${breakpoint(`tablet`)} {
    padding: 30px 0;
  }
`;

const Logo = styled.h1`
  display: block;
  width: 110px;
  margin: 0 auto;

  ${breakpoint(`tablet`)} {
    width: 95px;
  }
`;

const LogoLink = styled((props) => <Link {...props} />)`
  display: block;
  width: 100%;
  padding-top: 54.55%;
  background: url(${HeaderLogo}) no-repeat center center;
  background-size: contain;
  position: relative;
`;

const Content = styled.div`
  max-width: 1160px;
  padding: 0 20px;
  margin: 0 auto;
  height: calc(100vh - 360px);
  min-height: 600px;
  display: block;
  display: flex;
  align-items: center;

  dt,
  dd {
    padding: 30px 50px;
  }

  h2 {
    font-size: 180px;
    line-height: 1;
    display: block;
    color: #1cabe2;
    margin-bottom: 35px;
  }
  p {
    margin-top: 16px;
    margin-bottom: 36px;
  }

  dd {
    img {
      max-width: 100%;
    }
  }

  ${breakpoint(1160)} {
    h2 {
      font-size: 15.52vw;
    }

    dt,
    dd {
      padding: 0 4.31vw;
    }
  }
  ${breakpoint(`tablet`)} {
    height: auto;
    min-height: 0;

    padding-bottom: 50px;
    flex-wrap: wrap;
    flex-direction: column-reverse;
    max-width: 320px;

    dt,
    dd {
      width: 100%;
      padding: 0;
    }

    dd {
      margin-bottom: 40px;
      text-align: center;
      img {
        max-width: 240px;
      }
    }

    h2 {
      display: none;
    }
    ${Tit} {
      font-size: 20px;
    }
    p {
      margin-top: 8px;
    }
  }
  .tip {
    color: #b7b8ba;
    margin-top: 16px;
    font-size: 14px;
    ${breakpoint(640)} {
      font-size: 12px;
    }
  }
`;

const NotiBox = styled.div`
  background: #f8f9fa;
  padding: 15px;
  padding-top: 12px;

  .tit {
    color: #1cabe2;
    display: block;
    margin-bottom: 5px;
  }
  .dec {
    margin-bottom: 0;
  }
`;

const NotFound: React.FC<PageProps> = () => (
  <>
    <GlobalStyle />
    <Wrapper>
      <Header>
        <Logo>
          <LogoLink to="/">
            <span className="for-a11y">유니세프</span>
          </LogoLink>
        </Logo>
      </Header>
      <Content>
        <dt>
          <Tit size="s2" color="sky">
            현재 시스템 점검 중입니다
          </Tit>
          <p>웹사이트 이용에 불편을 드려 죄송합니다.</p>

          <NotiBox>
            <strong className="tit">점검일시</strong>
            <p className="dec">
              0000년 00월 00일 00시 00분 부터
              <br />
              0000년 00월 00일 00시 00분 까지
            </p>
          </NotiBox>
          <p className="tip">
            ※상기 점검 시간은 사정에 의해 변경될 수 있습니다.
            <br />
            예정된 시간에 작업이 완료될 수 있도록 최선을 다하겠습니다.
          </p>
        </dt>
        <dd>
          <Image pcSrc={ImgError} mobileSrc={ImgError} alt="" />
        </dd>
      </Content>
      <Footer />
    </Wrapper>
  </>
);

export default NotFound;
